import { useEffect, useRef, useState, useContext, useCallback } from "react"
import classNames from "classnames";
import IconButton from "../IconButton";
import RcSlider from 'rc-slider';
import { MediaContext } from '../MediaController';

import 'rc-slider/assets/index.css';
import './index.scss'

import playIcon from './assets/play-icon.svg'
import pauseIcon from './assets/pause-icon.svg'

const TIMEOUT = 500;

function AudioPlayer({ 
  onNextSlide, 
  nextSlideBreakpoint,
  audioFile, 
}) {
  const { setVoPlaying, voPlaying } = useContext(MediaContext)
  const audioRef = useRef(new Audio(audioFile))
  const soundRef = useRef(null);
  const currentTimeRef = useRef(0);
  const intervalRef = useRef(null);
  
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime])

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (nextSlideBreakpoint && currentTimeRef.current >= nextSlideBreakpoint) {
        onNextSlide();
      }
      setCurrentTime((value) => value + (TIMEOUT / 1000))
    }, TIMEOUT)
  }, [nextSlideBreakpoint, onNextSlide])

  const play = useCallback(() => {
    setVoPlaying(true);
  }, [setVoPlaying])

  const pause = useCallback(() => {
    clearInterval(intervalRef.current);
    setVoPlaying(false);
  }, [setVoPlaying])

  const stop = useCallback(() => {
    clearInterval(intervalRef.current);
    audioRef.current.pause();    
    setCurrentTime(0);
    setVoPlaying(false)
  }, [])

  useEffect(() => {
    if (voPlaying) {
      audioRef.current.muted = true
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }, [voPlaying])

  useEffect(() => {
    audioRef.current.muted = true
    audioRef.current.addEventListener('ended', stop)

    setVoPlaying(true)

    return () => {
      audioRef.current.removeEventListener('ended', stop)
    }
  }, [audioFile, setVoPlaying, stop])

  useEffect(() => {
    if (voPlaying) {
      startTimer()
    } else {
      clearInterval(intervalRef.current);
    }
  }, [voPlaying, startTimer])

  return (
    <div className="audio-player">
      <audio src={audioFile} ref={soundRef} />
      <IconButton
        className={classNames(
          'button',
          'player-button'
        )}
        onClick={voPlaying ? pause : play}
      >
        <img src={voPlaying ? pauseIcon : playIcon} alt="play/pause button"/>
      </IconButton>
      <RcSlider
        className="audio-progress"
        min={0}
        max={audioRef.current.duration}
        step={0.01}
        value={currentTime}
      />
    </div>
  )
}

export default AudioPlayer
