import classNames from "classnames";

import 'rc-slider/assets/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tapToPlace from './assets/tap-to-place-street-art.png';

import './index.scss'

function TapToPlaceOverlay({ experience, onClose }) {
  return (
    <div 
      className={
        classNames(
          "tap-to-place-overlay", 
        )
      }
    >
      <img src={tapToPlace} alt="tap the ground to place the street art"/>
    </div>
  )
}

export default TapToPlaceOverlay
