import { Children, cloneElement, isValidElement, useContext, useEffect, useRef, useState } from "react"
import { MenuContext } from "../MenuController"

import markerIcon from './assets/marker.svg'
import SITES from "../../data/sites"
import './index.scss'

const mapOptions = {
  center: { lat: 14.74, lng: -97.5 },
  zoom: 3.5,
  disableDefaultUI: true,
}

function Map({ children, options }) {
  const [map, setMap] = useState()
  const ref = useRef()

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current))
    }
  }, [ref, map])

  useEffect(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  return (
    <>
      <div className="world-map" id="map" ref={ref}></div>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map })
        }
      })}
    </>
  )
}

function Marker({ map, site }) {
  const [marker, setMarker] = useState()
  const { setActiveMarker } = useContext(MenuContext)

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker({
        icon: markerIcon,
      }))
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker, setActiveMarker, site])

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        map,
        position: {
          lat: site.lat,
          lng: site.lng,
        },
      })

      const listener = marker.addListener('click', () => {
        setActiveMarker(site.name)
      })

      return () => {
        window.google.maps.event.removeListener(listener)
      }
    }
  }, [map, marker, setActiveMarker, site])

  return null
}

function WorldMap() {
  const renderSiteMarkers = () => (
    SITES.map((site) => (
      <Marker
        key={site.name}
        site={site}
      />
    ))
  )

  return (
    <Map options={mapOptions}>
      {renderSiteMarkers()}
    </Map>
  )
}

export default WorldMap
