function asyncCalculateRoute(service, request) {
  return new Promise((resolve) => {
    service.route(request, (response, status) => {
      if (status === 'OK') {
        resolve(response)
      }
    })
  })
}

export default asyncCalculateRoute
