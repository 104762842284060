import { gsap } from "gsap"
import { useContext, useEffect, useLayoutEffect, useRef } from "react"
import { MenuContext } from "../MenuController"
import MenuSvg from "./assets/MenuSvg"
import Sites from '../Sites'
import Sources from "../Sources"

import sites from './assets/sites.png'
import sources from './assets/sources.png'
import './index.scss'

function Menu({ vpsMode }) {
  const {
    activePage,
    open,
    pageOpen,
    setActivePage,
    setPageOpen,
  } = useContext(MenuContext)
  const menuRef = useRef(null)
  const pageHolderRef = useRef(null)

  const toggleSites = () => {
    setActivePage('sites')
    setPageOpen(true)
  }

  const toggleSources = () => {
    setActivePage('sources')
    setPageOpen(true)
  }

  useLayoutEffect(() => {
    if (open) {
      if (vpsMode) {
        gsap.to(menuRef.current, {
          duration: 0.7,
          ease: 'expo.out',
          yPercent: 0,
        })
      } else {
        setActivePage("sources");
        setPageOpen(true)
      }
    } else {
      gsap.to(menuRef.current, {
        duration: 0.7,
        ease: 'expo.out',
        yPercent: 100,
        onComplete: function() {
          setActivePage(null)
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, vpsMode])

  useLayoutEffect(() => {
    if (pageOpen) {
      gsap.to(pageHolderRef.current, {
        duration: 0.7,
        ease: 'expo.out',
        xPercent: 0,
      })
    } else {
      gsap.to(pageHolderRef.current, {
        duration: 0.7,
        ease: 'expo.out',
        // onComplete: setActivePage(null),
        xPercent: 100,
      })
    }
  }, [pageOpen])

  useEffect(() => {
    if (pageOpen) {
      window.XR8.pause()
    } else {
      window.XR8.resume()
    }
  }, [pageOpen])

  return (
    <>
      <div className="menu" ref={menuRef}>
        <div className="svg-wrap">
          <MenuSvg />
        </div>
        <div className="options">
          <div className="menu-option" onClick={toggleSites}>
            <div className="option-icon">
              <img src={sites} alt="sites" />
            </div>
            <span className="option-label">Sites</span>
            <span className="spacer">.</span>
          </div>
          <div className="menu-option" onClick={toggleSources}>
            <div className="option-icon">
              <img src={sources} alt="sources" />
            </div>
            <span className="option-label">Sources</span>
            <span className="spacer">.</span>
          </div>
        </div>
      </div>
      <div className="page-holder" ref={pageHolderRef}>
        {activePage === "sites" && <Sites />}
        {activePage === "sources" && <Sources />}
      </div>
    </>
  );
}

export default Menu
