import rotateanim from './assets/rotateicon.png'

import './index.scss'

function RotateCurtain() {
  return (
    <div className="rotate-curtain">
      <img src={rotateanim} className="rotate-icon" alt="rotate phone" />
      <span>Please rotate phone.</span>
      <span>Experience must be viewed in portrait mode.</span>
    </div>
  )
}

export default RotateCurtain
