import { useState } from 'react'
import classNames from 'classnames'
import './index.scss'

import builtByRose from '../../assets/built-by-rose.png';

function InitialMessage({ onButtonClick }) {
  const [visible, setVisible] = useState(true)

  return (
    <div 
      className={classNames("initial-message", visible ? 'initial-message--visible' : 'initial-message--hidden')}
      onAnimationEnd={!visible ? onButtonClick : undefined}
    >
      <h1 className="title">Marching Forward</h1>
      <p className="subtitle">
        Celebrating Black history through stories of Black resistance. 
      </p>
      <p className="text">
        Walk along the Black Lives Matter text and explore important moments in history. As you approach new statues, tap to learn more about each historic moment of Black resistance.
      </p>
      <button 
        onClick={() => {
          setVisible(false);
        }} 
        className="button"
      >
        Explore
      </button>
      <img className="built-by-rose" src={builtByRose} alt="built by ROSE" />
      <p className="warning">
        <strong>Warning:</strong> This experience contains material on historical inequality and racial violence, which some may find distressing.
      </p>
    </div>
  )
}

export default InitialMessage
