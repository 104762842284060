import { useContext } from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";

import RotateCurtain from './components/RotateCurtain';
import Scene from './components/Scene';
import RouteMap from './components/RouteMap';
import InstructionDisplay from './components/InstructionDisplay';

import loadingSkinnerComponent from './aframe/components/loadingSkinner';
import proximityTriggerComponent from './aframe/components/proximityTrigger';
import reactBridgeComponent from './aframe/components/reactBridge'
import staticCubemapComponent from './aframe/components/staticCubemap';
import tapPlacerComponent from './aframe/components/tapPlacer';
import repositionComponent from './aframe/components/reposition';
import dragControlComponent from './aframe/components/dragControl';
import { RouteContext } from './components/RouteController';
import namedWayspotComponent from './aframe/components/namedWayspot';
import hiderMaterialComponent from './aframe/components/hiderMaterial';
import shadowShaderComponent from './aframe/components/shadowShader';
import vpsControllerComponent from './aframe/components/vpsController';
import debugDisplayComponent from "./aframe/components/debugDisplay";

import usePreloadAssets from "./hooks/usePreloadAssets";

import './App.css';


function App() {
  usePreloadAssets();
  const { showRoute, arrived, selectedGuideMe } = useContext(RouteContext)

  return (
    <Wrapper apiKey="AIzaSyDieFF2hOZx8etgI5QSOQUrg-TfYOJrGHM">
      <RotateCurtain />
      <Scene
        components={[
          { name: "loading-skinner", val: loadingSkinnerComponent },
          { name: "proximity-trigger", val: proximityTriggerComponent },
          { name: "react-bridge", val: reactBridgeComponent },
          { name: "static-cubemap", val: staticCubemapComponent },
          { name: "tap-placer", val: tapPlacerComponent },
          { name: "reposition", val: repositionComponent },
          { name: "drag-control", val: dragControlComponent },
          { name: "named-wayspot", val: namedWayspotComponent },
          { name: "hider-material", val: hiderMaterialComponent },
          { name: "shadow-shader", val: shadowShaderComponent },
          { name: "vps-controller", val: vpsControllerComponent },
          { name: "debug-display", val: debugDisplayComponent },
        ]}
      />
      {showRoute && (
        <div className="route-ui">
          {selectedGuideMe ? !arrived && <RouteMap /> : <RouteMap />}
          {!arrived && <InstructionDisplay />}
        </div>
      )}
    </Wrapper>
  );
}

export default App;
