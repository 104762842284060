const THREE = window.THREE


const dragControl = {
  schema: {
    enabled: { default: false },
    pointerId: { default: null },
  },
  init() {
    this.dragging = false
    this.experience = document.getElementById('experience');
    this.experienceWrapper = document.getElementById('experience-wrapper');
    this.pointer = document.getElementById('hold-drag-position-pointer');
    
    this.setDragging = this.setDragging.bind(this)
    this.finishDragging = this.finishDragging.bind(this)
    
    let experienceCenter = new THREE.Vector3();
    this.experience.object3D.getWorldPosition(experienceCenter);
    
    this.el.setAttribute('position', `${experienceCenter.x} ${experienceCenter.y} ${experienceCenter.z}`);
    this.el.object3D.rotation.y += this.experienceWrapper.object3D.rotation.y;

    this.pointer.setAttribute('position', `0 0 11`)

    this.el.addEventListener('mousedown', this.setDragging)
    this.el.addEventListener('mouseup', this.finishDragging)
  },
  tick() {
    if (this.data.enabled && this.dragging) {
      let pointerPosition = new THREE.Vector3();
      this.pointer.object3D.getWorldPosition(pointerPosition);
  
      this.experienceWrapper.setAttribute('position', `${pointerPosition.x} ${pointerPosition.y} ${pointerPosition.z}`)
    }
  },
  setDragging(e) {
    this.dragging = true
  },
  finishDragging() {
    this.dragging = false
  },
  remove() {
    this.el.removeEventListener('mousedown', this.setDragging)
  }
}

export default dragControl
