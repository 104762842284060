import classNames from 'classnames'
import Flickity from 'react-flickity-component'

import './index.scss'

function TextCarousel({ items }) {
  const renderTextItems = () => (
    items.map((item) => (
      <div className="text-item-wrapper" key={item}>
        <p className="text-item" dangerouslySetInnerHTML={{ __html: item }}></p>
      </div>
    ))
  )

  return (
    <Flickity
      className={classNames('text-carousel', { 'show-dots': items.length > 1 })}
      options={{
        contain: true,
        draggable: true,
        freeScroll: false,
        pageDots: true,
        prevNextButtons: false,
      }}
      reloadOnUpdate
    >
      {renderTextItems()}
    </Flickity>
  )
}

export default TextCarousel
