import { gsap } from "gsap"
import { useContext, useRef } from "react"
import classNames from "classnames"
import IconButton from "../IconButton"
import { MenuContext } from "../MenuController"
import MenuSvg from "./assets/MenuSvg"

import './index.scss'

function MenuButton() {
  const { open, setOpen } = useContext(MenuContext)
  const ref = useRef()

  const handleClick = () => {
    gsap.to(ref.current, {
      rotate: open ? 0 : 90,
      duration: 0.2,
    })

    setOpen(!open)
  }

  return (
    <IconButton className={classNames({ open })} onClick={handleClick}>
      <div className="svg-wrapper" ref={ref}>
        <MenuSvg />
      </div>
    </IconButton>
  )
}

export default MenuButton
