import { useContext } from "react"
import IconButton from "../IconButton"
import { MediaContext } from "../MediaController"
import MutedSvg from "./assets/MutedSvg"
import UnmutedSvg from "./assets/UnmutedSvg"

function SoundButton() {
  const { muted, setMuted } = useContext(MediaContext)

  const handleClick = () => {
    setMuted(!muted)
  }

  return (
    <IconButton onClick={handleClick}>
      {muted ? <MutedSvg /> : <UnmutedSvg />}
    </IconButton>
  )
}

export default SoundButton
