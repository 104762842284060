import { useCallback, useEffect } from 'react'
import classNames from 'classnames'

import IconButton from "../IconButton";

import OnSiteLocationIcon from "./assets/OnSiteLocationIcon.png";

import './index.scss'

function OnSiteLocationButton({ active, onClick }) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick])

  return (
    <IconButton
      className={classNames(
        "on-site-location-button",
        active && "on-site-location-button--active"
      )}
      onClick={handleClick}
    >
      <img src={OnSiteLocationIcon} alt="location icon" />
    </IconButton>
  );
}

export default OnSiteLocationButton
