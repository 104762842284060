import { useCallback, useContext, useEffect, useRef, useState } from "react";
import InitialMessage from "../InitialMessage";
import MilestoneExperience from "../MilestoneExperience";
import LocationExperience from "../LocationExperience";
import TapStatueOverlay from "../TapStatueOverlay";
import TapToPlaceOverlay from "../TapToPlaceOverlay";
import RepositionOverlay from "../RepositionOverlay";
import RepositionButton from "../RepositionButton";
import OnSiteLocationButton from "../OnSiteLocationButton";
import { MediaContext } from "../MediaController";
import { RouteContext } from "../RouteController";
import NearbySiteMessage from "../NearbySiteMessage";

import STATUES from "../../data/statues";

import builtByRose from "../../assets/built-by-rose.png";

import classNames from "classnames";

import "./index.scss";
import { MenuContext } from "../MenuController";
import CoachingOverlay from "../CoachingOverlay";

const { dataLayer } = window;

const Experience = ({ vpsMode, setVpsMode }) => {
  const [experienceOpenedTimestamp, setExperienceOpenedTimestamp] =
    useState(null);

  const visitedMilestenesRef = useRef([]);
  const openedMilestenesRef = useRef([]);

  const [showInitialMessage, setShowInitialMessage] = useState(false);
  const [showLocationExperience, setShowLocationExperience] = useState(false);
  const [currentExperienceId, setCurrentExperienceId] = useState(null);
  const [showTapStatueOverlay, setShowTapStatueOverlay] = useState(false);
  const [showTapGroundOverlay, setShowTapGroundOverlay] = useState(false);
  const [experiencePlaced, setExperiencePlaced] = useState(false);
  const [started, setStarted] = useState(false);

  const [hideUI, setHideUI] = useState(false);
  const [repositionActive, setRepositionActive] = useState(false);

  const [showNearbySiteMessage, setShowNearbySiteMessage] = useState(false);

  const {
    nearbySite,
    arrived,
    rejectRoute,
    setSelectedGuideMe
  } = useContext(RouteContext);
  const { open: menuOpen } = useContext(MenuContext);

  const experienceData =
    currentExperienceId && STATUES.find(({ id }) => id === currentExperienceId);

  const showRecorderButton = () => {
    const recorder = document.getElementById("recorder");
    const recorderButton = document.getElementById("recorder-button");
    if (recorder) {
      recorder.classList.add("visible");
    }

    if (recorderButton) {
      recorderButton.addEventListener("mousedown", () => {
        window.dispatchEvent(new CustomEvent("recorder-button-down"));
      });
      recorderButton.addEventListener("touchstart", () => {
        window.dispatchEvent(new CustomEvent("recorder-button-down"));
      });
      recorderButton.addEventListener("mouseup", () => {
        window.dispatchEvent(new CustomEvent("recorder-button-up"));
      });
      recorderButton.addEventListener("touchend", () => {
        window.dispatchEvent(new CustomEvent("recorder-button-up"));
      });
    }
  };

  const handleRepositionClick = useCallback(() => {
    if (!repositionActive) {
      const scene = document.getElementById("a-frame-scene");
      if (scene) {
        // scene.emit('recenter')
      }
    }
    setRepositionActive((val) => !val);
  }, [repositionActive]);

  const handleCloseMilestoneExperience = useCallback(() => {
    const textBasic = document.getElementById("text-basic");
    const textTextured = document.getElementById("text-textured");
    const textTexturedForLocation = document.getElementById(
      "text-textured-for-location"
    );

    textTextured.setAttribute(
      "animation",
      `property: opacity; from: 1; to: 0; loop: false; dur: 200`
    );
    if (vpsMode && arrived) {
      textTexturedForLocation.setAttribute(
        "animation",
        "property: opacity; from: 0; to: 1; loop: false; dur: 200"
      );
    } else {
      textBasic.setAttribute(
        "animation",
        "property: opacity; from: 0; to: 1; loop: false; dur: 200"
      );
    }

    setCurrentExperienceId(null);
  }, [arrived, vpsMode]);

  const handleExploreVpsMode = useCallback(() => {
    const textBasic = document.getElementById("text-basic");
    const textTexturedForLocation = document.getElementById("text-textured-for-location");

    textBasic.setAttribute(
      "animation",
      `property: opacity; from: 1; to: 0; loop: false; dur: 200`
    );
    textTexturedForLocation.setAttribute(
      "animation",
      "property: opacity; from: 0; to: 1; loop: false; dur: 200"
    );
  }, [])

  const handleExperienceModalUnmount = useCallback(() => {
    const seconds = Math.round((Date.now() - experienceOpenedTimestamp) / 1000);
    setExperienceOpenedTimestamp(null);
    dataLayer.push({
      event: "milestone-experience-closed",
      milestone_id: currentExperienceId,
      time: seconds,
    });
  }, [currentExperienceId, experienceOpenedTimestamp]);

  const selectTapToPlace = () => {
    setShowNearbySiteMessage(false);

    setTimeout(() => {
      setShowTapGroundOverlay(true);
    }, 250);
  };

  const selectGuideMe = () => {
    setShowNearbySiteMessage(false);
    setVpsMode(true);
    setSelectedGuideMe(true);
  };

  useEffect(() => {
    const handleExperiencePlaced = () => {
      rejectRoute();
      setExperiencePlaced(true);
      setShowInitialMessage(true);
    };

    const handleStatueTapped = ({ detail: exId }) => {
      setExperienceOpenedTimestamp(Date.now());
      dataLayer.push({
        event: "milestone-experience-opened",
        milestone_id: exId,
      });
      setShowLocationExperience(false);
      setCurrentExperienceId(exId);

      if (!openedMilestenesRef.current.includes(exId)) {
        openedMilestenesRef.current.push(exId);
        dataLayer.push({
          event: `opened-unique-experiences-${openedMilestenesRef.current.length}`,
        });
      }
    };

    const handleGetCloseToTheStatue = (event) => {
      const milestoneId = event.detail.el.id;

      if (!visitedMilestenesRef.current.includes(milestoneId)) {
        visitedMilestenesRef.current.push(milestoneId);
        dataLayer.push({
          event: `visited-unique-milestones-${visitedMilestenesRef.current.length}`,
        });
      }
    };

    const handleRecorderButtonDown = () => {
      setHideUI(true);
    };

    const handleSnapshotPreviewClosed = () => {
      setHideUI(false);
    };

    const toggleShowTapStatueOverlay = ({ detail: shouldShow }) => {
      setShowTapStatueOverlay(shouldShow);
    };

    window.addEventListener("experience-placed", handleExperiencePlaced);
    window.addEventListener("statuetapped", handleStatueTapped);
    window.addEventListener("enteredproximity", handleGetCloseToTheStatue);
    window.addEventListener("recorder-button-down", handleRecorderButtonDown);
    window.addEventListener(
      "mediarecorder-previewclosed",
      handleSnapshotPreviewClosed
    );
    window.addEventListener(
      "should-show-tap-overlay",
      toggleShowTapStatueOverlay
    );

    return () => {
      window.removeEventListener("experience-placed", handleExperiencePlaced);
      window.removeEventListener("statuetapped", handleStatueTapped);
      window.removeEventListener("enteredproximity", handleGetCloseToTheStatue);
      window.removeEventListener(
        "recorder-button-down",
        handleRecorderButtonDown
      );
      window.removeEventListener(
        "mediarecorder-previewclosed",
        handleSnapshotPreviewClosed
      );
      window.removeEventListener(
        "should-show-tap-overlay",
        toggleShowTapStatueOverlay
      );
    };
  }, []);

  useEffect(() => {
    if (nearbySite === false) {
      setShowTapGroundOverlay(true);
    } else if (nearbySite) {
      setShowNearbySiteMessage(true);
    }
  }, [nearbySite]);

  useEffect(() => {
    if (showTapGroundOverlay) {
      const camera = document.getElementById("camera");

      setTimeout(() => {
        camera.setAttribute("tap-placer", "");
      }, 100);
    }
  }, [showTapGroundOverlay]);

  useEffect(() => {
    if (arrived && vpsMode) {
      const camera = document.getElementById("camera");

      camera.setAttribute(
        "vps-controller",
        `wayspotName: ${nearbySite.id}; txtImage: ${nearbySite.txtImage}`
      );
    }
  }, [arrived, vpsMode]);

  return (
    <>
      {started && (
        <div className="experience-header">
          <h1 className="title">MARCHING FORWARD</h1>
          <img
            className="built-by-rose"
            src={builtByRose}
            alt="built by ROSE"
          />
        </div>
      )}
      <div
        className={classNames(
          "experience-wrapper",
          hideUI ? "experience-wrapper--hidden" : "experience-wrapper--visible"
        )}
      >
        {!started &&
          !repositionActive &&
          showTapGroundOverlay &&
          !experiencePlaced && <TapToPlaceOverlay />}
        {started && !repositionActive && showTapStatueOverlay && (
          <TapStatueOverlay />
        )}
        {started && repositionActive && <RepositionOverlay />}
        {showNearbySiteMessage && (
          <NearbySiteMessage
            selectTapToPlace={selectTapToPlace}
            selectGuideMe={selectGuideMe}
          />
        )}
        {showInitialMessage && !showLocationExperience && (
          <InitialMessage
            onButtonClick={() => {
              if (arrived && vpsMode) {
                setShowLocationExperience(true);
                handleExploreVpsMode();
              }
              setShowInitialMessage(false);
              showRecorderButton();
              setStarted(true);
            }}
          />
        )}
        {started && experienceData && (
          <MilestoneExperience
            experience={experienceData}
            onClose={handleCloseMilestoneExperience}
            invisible={repositionActive}
            key={currentExperienceId}
            onUnmount={handleExperienceModalUnmount}
          />
        )}
        {started && !vpsMode && (
          <RepositionButton
            active={repositionActive}
            onClick={handleRepositionClick}
          />
      )}
        {vpsMode &&
          arrived &&
          !started &&
          !showInitialMessage &&
          !showLocationExperience && (
            <CoachingOverlay
              image={nearbySite.image}
              wayspotName={nearbySite.wayspotName}
              key={`coaching-overlay-${nearbySite.id}`}
            />
          )}
        {showLocationExperience && (
          <LocationExperience
            experience={nearbySite}
            key={`location-experience-${nearbySite.id}`}
            invisible={repositionActive}
            onClose={() => {
              setShowLocationExperience(false);
            }}
          />
        )}
        {arrived && vpsMode &&
          !showNearbySiteMessage &&
          !menuOpen && (
            <OnSiteLocationButton
              active={showLocationExperience}
              onClick={() => {
                if (started && experienceData) {
                  handleCloseMilestoneExperience();
                }
                setShowLocationExperience(true);
              }}
            />
          )}
      </div>
    </>
  );
};

export default Experience;
