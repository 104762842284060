import guideGif from "../../assets/VPS_Guide.gif";

import './index.scss'

function CoachingOverlay({ image, wayspotName }) {
  return (
    <div className={"coaching-overlay"}>
      <div className="image-wrapper">
        <div className="image-border">
          <img
            src={image}
            alt="wayspot"
          />
        </div>
        <div className="gif-wrapper">
          <img
            src={guideGif}
            alt="coaching-animation"
          />
        </div>
      </div>
      <div className="text-wrapper">
        Point the camera at the {wayspotName} site and move your device
        around to activate the experience.
      </div>
    </div>
  );
}

export default CoachingOverlay
