function showTexturedText(textureSrc) {
  const texturedTextOpacity = this.textTextured.getAttribute("opacity");

  this.textTextured.setAttribute(
    "animation",
    `property: opacity; from: ${texturedTextOpacity}; to: 0; loop: false; dur: 200`
  );

  if (this.textTexturedForLocation) {
    this.textTexturedForLocation.setAttribute(
      "animation",
      "property: opacity; from: 0; to: 1; loop: false; dur: 200"
    );
  } else {
    this.textBasic.setAttribute(
      "animation",
      "property: opacity; from: 0; to: 1; loop: false; dur: 200"
    );
  }

  setTimeout(() => {
    this.textTextured.setAttribute("src", textureSrc);
    this.textTextured.setAttribute(
      "animation",
      "property: opacity; from: 0; to: 1; loop: false; dur: 500"
    );

    if (this.textTexturedForLocation) {
      this.textTexturedForLocation.setAttribute(
        "animation",
        "property: opacity; from: 1; to: 0; loop: false; dur: 500"
      );
    } else {
      this.textBasic.setAttribute(
        "animation",
        "property: opacity; from: 1; to: 0; loop: false; dur: 500"
      );
    }
  }, 500);
}

export default showTexturedText;
