const reactBridgeComponent = {
  init() {
    this.el.sceneEl.addEventListener('realityready', this.handleRealityReady)
    this.el.sceneEl.addEventListener('realityerror', this.handleRealityError)
  },
  handleRealityReady() {
    if (global.handleRealityReady) {
      global.handleRealityReady()
    }
  },
  handleRealityError() {
    if (global.handleRealityError) {
      global.handleRealityError()
    }
  }
}

export default reactBridgeComponent
