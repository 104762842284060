import { createContext, useState, useMemo, useEffect, useRef } from "react"

import backgroundSound from '../../assets/audio/0_BG Audio_quiet.mp3'

export const MediaContext = createContext({
  muted: true,
  setMuted: () => {}
})

function MediaController({ children }) {
  const [audioFile, setAudioFile] = useState(null);
  const [muted, setMuted] = useState(false);
  const [voPlaying, setVoPlaying] = useState(false)
  const backgroundSoundRef = useRef(null);
  const voRef = useRef(null)
  const wasPlayingVo = useRef(true)
  const wasPlayingBackground = useRef(false)

  const stopVo = () => {
    setVoPlaying(false)
    voRef.current.currentTime = 0
  }

  const value = useMemo(() => ({
    audioFile,
    muted,
    voPlaying,
    setAudioFile,
    setMuted,
    setVoPlaying,
    stopVo,
  }), [audioFile, muted, voPlaying])

  useEffect(() => {
    let savedState = null;

    const handleSnapshotPreviewOpened = () => {
      setMuted((value) => {
        savedState = value;
        return true
      });
    }

    const handleSnapshotPreviewClosed = () => {
      setMuted(savedState);
    }

    window.addEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)
    window.addEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)

    return () => {
      window.removeEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)
      window.removeEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)
    }
  }, [])

  useEffect(() => {
    if (voPlaying) {
      voRef.current.play()
      wasPlayingVo.current = true
    } else {
      voRef.current.pause()
      wasPlayingVo.current = false
    }
  }, [audioFile, voPlaying])

  useEffect(() => {
    if (backgroundSoundRef.current) {
      backgroundSoundRef.current.muted = muted
    }

    if (voRef.current) {
      voRef.current.muted = muted
    }
  }, [muted])

  useEffect(() => {
    window.setVoPlaying = setVoPlaying
  }, [])

  useEffect(() => {
    const userInteractionHandler = () => {
      backgroundSoundRef.current.loop = true
      backgroundSoundRef.current.play()
      backgroundSoundRef.current.pause()
      voRef.current.play()
      voRef.current.pause()

      window.removeEventListener('user-interaction', userInteractionHandler);
    }

    const experiencePlacedHandler = () => {
      backgroundSoundRef.current.play();
      wasPlayingBackground.current = true;
      window.removeEventListener('experience-placed', experiencePlacedHandler);
    }

    window.addEventListener('user-interaction', userInteractionHandler);
    window.addEventListener('experience-placed', experiencePlacedHandler);

    return () => {
      window.removeEventListener('user-interaction', userInteractionHandler);
      window.removeEventListener('experience-placed', experiencePlacedHandler);
    }
  }, [])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'visible') {
        voRef.current.pause()
        backgroundSoundRef.current.pause()
      } else if (document.visibilityState === 'visible') {
        if (wasPlayingVo.current) {
          voRef.current.play()
        }

        if (wasPlayingBackground.current) {
          backgroundSoundRef.current.play()
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    if (document.visibilityState !== 'visible') {
      voRef.current.pause()
      backgroundSoundRef.current.pause()
    }

    return function() {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return (
    <MediaContext.Provider
      value={value}
    >
      <audio src={audioFile} ref={voRef} />
      <audio src={backgroundSound} ref={backgroundSoundRef} loop />
      {children}
    </MediaContext.Provider>
  )
}

export default MediaController
