import { useEffect, useState } from "react"
import Menu from "../Menu"
import Toolbar from "../Toolbar"

import sceneHtml from './index.html'
import './index.scss'
import Experience from "../Experience"

const AFRAME = window.AFRAME

const registeredComponents = new Set()
const registerComponents = components => components.forEach(({name, val}) => {
  if (registeredComponents.has(name) || name in AFRAME.components) {
    return
  }
  registeredComponents.add(name)
  AFRAME.registerComponent(name, val)
})

const registeredSystems = new Set()
const registerSystems = systems => systems.forEach(({name, val}) => {
  if (registeredSystems.has(name) || name in AFRAME.systems) {
    return
  }
  registeredSystems.add(name)
  AFRAME.registerSystem(name, val)
})

const registeredPrimitives = new Set()
const registerPrimitives = primitives => primitives.forEach(({name, val}) => {
  if (registeredPrimitives.has(name) || name in AFRAME.primitives.primitives) {
    return
  }
  registeredPrimitives.add(name)
  AFRAME.registerPrimitive(name, val)
})

function LoadingError() {
  const handleClick = () => {
    window.location.reload()
  }

  return (
    <div className="loading-error">
      <div className="inner-content">
        <h1 className="title">Marching Forward</h1>
        <h2 className="subtitle">Celebrating Black history through stories of Black resistance.</h2>
        <p className="body">This AR experience requires access to your motion sensors, camera, and location. Grant these permissions to continue.</p>
        <button className="cta" onClick={handleClick}>Allow Access</button>
      </div>
      <div className="logos">
        <img className="foreground-image poweredby-img" src="//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-4.svg" alt="powered by 8th wall" />
      </div>
    </div>
  )
}

function Scene({
  components = [],
  systems = [],
  primitives = [],
}) {
  const [realityError, setRealityError] = useState(false)
  const [realityReady, setRealityReady] = useState(false)
  const [vpsMode, setVpsMode] = useState(false);
  const [xrReady, setXRReady] = useState(false)

  useEffect(() => {
    if (components) {
      registerComponents(components)
    }
    if (systems) {
      registerSystems(systems)
    }
    if (primitives) {
      registerPrimitives(primitives)
    }
  }, [components, systems, primitives])

  useEffect(() => {
    const watchWindow = setInterval(() => {
      if (window.XR8 && window.AFRAME && window.XRExtras) {
        console.log('XR8 ready watcher');
        setXRReady(true);
        clearInterval(watchWindow);
      } else {
        console.log('loading watcher');
      }
    }, 200)
  }, [])

  useEffect(() => {
    if (xrReady) {
      window.handleRealityReady = function() {
        setRealityReady(true)
        delete window.handleRealityReady
      }
      window.handleRealityError = function() {
        console.log('here')
        setRealityError(true)
        delete window.handleRealityError
      }

      const html = document.querySelector('html')
      const origHtmlClass = html.className
      document.body.insertAdjacentHTML('beforeend', sceneHtml)

      // Cleanup
      return () => {
        const ascene = document.querySelector('a-scene')
        ascene.parentNode.removeChild(ascene)
        html.className = origHtmlClass
      }
    }
  }, [xrReady])

  useEffect(() => {
    const tmpFile = new File([new Blob()], 'tmp.mp4', {
      type: 'video/mp4',
      lastModified: Date.now(),
    })
    const shareTestObj = {
      files: [tmpFile],
    }

    if (navigator.canShare && navigator.canShare(shareTestObj)) {
      document.documentElement.style.setProperty('--displaysavebutton', 'none')
    }
  }, [])

  return (
    <>
      {realityReady && (
        <>
          <Toolbar vpsMode={vpsMode} />
          <Menu vpsMode={vpsMode} />
          <Experience vpsMode={vpsMode} setVpsMode={setVpsMode} />
        </>
      )}
      {realityError && <LoadingError />}
    </>
  );
}

export default Scene
