import classNames from 'classnames'
import { useContext, useState } from 'react'
import { RouteContext } from '../RouteController'

import nearbyIcon from './assets/nearby-icon.png'
import './index.scss'

function NearbySiteMessage({ selectTapToPlace, selectGuideMe }) {
  const [visible, setVisible] = useState(true)
  const { rejectRoute, setShowDisplay } = useContext(RouteContext)

  const handleTapToPlace = () => {
    setVisible(false)
    rejectRoute()
    window.dispatchEvent(new CustomEvent("user-interaction"));

    setTimeout(() => {
      selectTapToPlace()
    }, 450)
  }

  const handleGuideMe = () => {
    setVisible(false)
    setShowDisplay(true)
    window.dispatchEvent(new CustomEvent("user-interaction"));

    setTimeout(() => {
      selectGuideMe()
    }, 450)
  }

  return (
    <div
      className={classNames("nearby-site-message", visible ? 'nearby-site-message--visible' : 'nearby-site-message--hidden')}
      onAnimationEnd={undefined}
    >
      <img className="icon" src={nearbyIcon} alt="nearby site" />
      <p className="subtitle">Nearby Site Detected</p>
      <p className="text">It looks like you are near one of our specified locations. To further highlight Black stories, the team at ROSE has selected special sites to activate this experience. Do you want to navigate to that area?</p>
      <div className="buttons">
        <button className="button tap-to-place" onClick={handleTapToPlace}>Tap to Place</button>
        <button className="button guide-me" onClick={handleGuideMe}>Guide Me</button>
      </div>
    </div>
  )
}

export default NearbySiteMessage
