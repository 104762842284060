import classNames from "classnames";

import 'rc-slider/assets/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tapOverlay from './assets/tap-overlay.png';

import './index.scss'

function TapStatueOverlay() {
  return (
    <div 
      className={
        classNames(
          "tap-statue-overlay", 
        )
      }
    >
      <img src={tapOverlay} alt="tap the statue"/>
    </div>
  )
}

export default TapStatueOverlay
