import { useCallback, useEffect } from 'react'
import classNames from 'classnames'

import IconButton from "../IconButton";

import repositionIcon from './assets/Reposition Icon.svg';
import repositionDoneIcon from './assets/Reposition_Done Icon.svg';

import './index.scss'

function RepositionButton({ active, onClick }) {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick])

  useEffect(() => {
    const scene = document.getElementById('a-frame-scene');
    const experienceCenterPointer = document.getElementById('experience-center-pointer');

    if (scene && experienceCenterPointer) {
      if (active) {
        scene.setAttribute('reposition', '');
        experienceCenterPointer.setAttribute('drag-control', 'enabled: true')
        experienceCenterPointer.setAttribute('xrextras-hold-drag', 'riseHeight: 0; dragDelay: 100;');
        experienceCenterPointer.classList.add('cantap');
      } else {
        scene.removeAttribute('reposition');
        experienceCenterPointer.setAttribute('drag-control', 'enabled: false')
        experienceCenterPointer.removeAttribute('xrextras-hold-drag');
        experienceCenterPointer.classList.remove('cantap');
      }
    }
  }, [active])

  return (
    <IconButton
      className={classNames(
        'reposition-button',
        active && 'reposition-button--active',
      )}
      onClick={handleClick}
    >
      <img src={active ? repositionDoneIcon : repositionIcon} alt="icon"/>
    </IconButton>
  )
}

export default RepositionButton
