const THREE = window.THREE;

const debugDisplay = {
  init() {
    this.debug = document.createElement('div')
    this.debug.style = "width: 100vw; height: 20px; background-color: white; color: black; font-size: 16px; z-index: 9999999; position: absolute; top: 0; left: 0;";

    this.experienceWrapper = document.getElementById("experience-wrapper");
    this.camera = document.getElementById("camera");

    this.worldPosition = new THREE.Vector3();
    this.cameraWorldPosition = new THREE.Vector3();

    document.body.appendChild(this.debug)
  },
  tick() {
    this.experienceWrapper.object3D.getWorldPosition(this.worldPosition);
    this.camera.object3D.getWorldPosition(this.worldPosition);

    // x: ${this.experienceWrapper.object3D.position.x.toFixed(2)},
    // y: ${this.experienceWrapper.object3D.position.y.toFixed(2)},
    // z: ${this.experienceWrapper.object3D.position.z.toFixed(2)},
    // wx: ${this.worldPosition.x.toFixed(2)},
    // wy: ${this.worldPosition.y.toFixed(2)},
    // wz: ${this.worldPosition.z.toFixed(2)},
    // sx: ${this.experienceWrapper.object3D.scale.x},
    // sy: ${this.experienceWrapper.object3D.scale.y},
    // sz: ${this.experienceWrapper.object3D.scale.z},
    // cx: ${this.cameraWorldPosition.x.toFixed(2)},
    // cy: ${this.cameraWorldPosition.y.toFixed(2)},
    // cz: ${this.cameraWorldPosition.z.toFixed(2)},
    this.debug.innerHTML = `
      counter ${window.COUNTER}
    `;
  }
}
export default debugDisplay