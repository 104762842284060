function MenuSvg() {
  return (
    <svg width="7" height="25" viewBox="0 0 7 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3.22581" cy="3.22581" r="3.22581" fill="#5B42E1"/>
      <circle cx="3.22581" cy="12.9031" r="3.22581" fill="#5B42E1"/>
      <circle cx="3.22581" cy="21.7744" r="3.22581" fill="#5B42E1"/>
    </svg>
  )
}

export default MenuSvg
