import MediaController from "../MediaController";
import MenuController from "../MenuController";
import RouteController from "../RouteController";

function ContextProvider({ children }) {
  return (
    <RouteController>
      <MediaController>
        <MenuController>
          {children}
        </MenuController>
      </MediaController>
    </RouteController>
  )
}

export default ContextProvider
