import statues from '../../data/statues';

function showStatues() {
  statues.forEach(statue => {
    const modelElement = document.getElementById(statue.id);

    if (modelElement) {
      modelElement.setAttribute('proximity-trigger', '')
      modelElement.setAttribute('visible', 'true')
      const tapboxes = modelElement.querySelectorAll('.cantap');

      modelElement.addEventListener("enteredproximity", () => {
        console.log('enteredproximity')
        if (!this.activeStatues.includes(statue.id)) {
          this.activeStatues.push(statue.id);
        }

        modelElement.emit("should-show-tap-overlay", true)
      })
      modelElement.addEventListener("exitedproximity", () => {
        this.activeStatues = this.activeStatues.filter((id) => id !== statue.id)

        if (!this.activeStatues.length) {
          modelElement.emit("should-show-tap-overlay", false)
        }
      })

      if (tapboxes.length) {
        tapboxes.forEach((box) => {
          box.addEventListener("click", () => {
            if (this.activeStatues.includes(statue.id)) {
              modelElement.emit("statuetapped", statue.id)
              window.setVoPlaying(true)

              this.showTexturedText(statue.textSrc)
            }
          })
        })
      }
    }
  })
}

export default showStatues;