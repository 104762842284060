import { createContext, useMemo, useState } from "react";

export const RouteContext = createContext({
  nearbySite: null,
  setNearbySite: () => {},
  userCoords: null,
  setUserCoords: () => {},
  userHeading: 0,
  setUserHeading: () => {},
  route: null,
  setRoute: () => {},
  showDisplay: false,
  setShowDisplay: () => {},
  showRoute: true,
  rejectRoute: () => {},
  arrived: false,
  setArrived: () => {},
})

function RouteController({ children }) {
  const [nearbySite, setNearbySite] = useState(null)
  const [userCoords, setUserCoords] = useState(null)
  const [userHeading, setUserHeading] = useState(0)
  const [route, setRoute] = useState(null)
  const [showDisplay, setShowDisplay] = useState(false)
  const [showRoute, setShowRoute] = useState(true)
  const [arrived, setArrived] = useState(false)
  const [selectedGuideMe, setSelectedGuideMe] = useState(false);

  const value = useMemo(
    () => ({
      nearbySite,
      setNearbySite,
      userCoords,
      setUserCoords,
      userHeading,
      setUserHeading,
      route,
      setRoute,
      showDisplay,
      setShowDisplay,
      showRoute,
      rejectRoute: () => setShowRoute(false),
      arrived,
      setArrived,
      selectedGuideMe,
      setSelectedGuideMe,
    }),
    [
      nearbySite,
      setNearbySite,
      userCoords,
      setUserCoords,
      userHeading,
      setUserHeading,
      route,
      setRoute,
      showDisplay,
      setShowDisplay,
      showRoute,
      setShowRoute,
      arrived,
      setArrived,
      selectedGuideMe,
      setSelectedGuideMe,
    ]
  );

  return (
    <RouteContext.Provider value={value}>
      {children}
    </RouteContext.Provider>
  )
}

export default RouteController
