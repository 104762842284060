import { useCallback, useContext, useEffect, useRef, useState } from "react"
import classNames from "classnames";
import IconButton from "../IconButton";
import Slider from "react-slick";
import AudioPlayer from '../AudioPlayer';

import 'rc-slider/assets/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrowButton from './assets/arrow-button.svg';
import closeButton from './assets/close-button.svg';
import { MediaContext } from "../MediaController";

import './index.scss'

const carouselSettings = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: false,
  dotsClass: 'carousel-dots'
};

function MilestoneExperience({ experience, onClose, invisible, onUnmount }) {
  const [visible, setVisible] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [currentAutoplaySlide, setNextAutoplaySlide] = useState(0);
  const { stopVo, setAudioFile } = useContext(MediaContext);

  const carouselRef = useRef(null);

  const toggleExpanded = useCallback(() => {
    setExpanded((currentValue) => !currentValue);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
    stopVo();
  }, [stopVo]);

  const handleNextSlide = useCallback(() => {
    if (autoplay) {
      carouselRef.current.slickNext();
      setNextAutoplaySlide((val) => val + 1);
    }
  }, [autoplay]);

  useEffect(() => {
    if (experience) {
      setAudioFile(experience.audio);
    }

    return () => {
      setAudioFile(null);
    }
  }, [experience, setAudioFile]);

  useEffect(() => {
    return onUnmount;
  }, []);

  return (
    <div
      className={classNames(
        "milestone-experience",
        visible
          ? "milestone-experience--visible"
          : "milestone-experience--hidden",
        { "milestone-experience--expanded": expanded },
        invisible && "milestone-experience--invisible"
      )}
      onAnimationEnd={!visible ? onClose : undefined}
    >
      <div className={classNames("header")}>
        <h1 className="title">{experience.title}</h1>
        <div className="button-wrapper">
          <IconButton
            className={classNames(
              "button",
              "header-button",
              "fold-button",
              expanded && "fold-button--expanded",
              !expanded && "fold-button--folded"
            )}
            onClick={toggleExpanded}
          >
            <img src={arrowButton} alt="fold button" />
          </IconButton>
          <IconButton
            className={classNames(
              "button",
              "header-button",
              "close-button",
              !expanded && "close-button--hidden"
            )}
            onClick={handleClose}
          >
            <img src={closeButton} alt="close button" />
          </IconButton>
        </div>
      </div>
      <div className="content">
        <Slider
          {...carouselSettings}
          ref={carouselRef}
          onSwipe={() => {
            setAutoplay(false);
          }}
        >
          {experience.slides.map((slide, index) => (
            <p className="text" key={index}>
              {slide}
            </p>
          ))}
        </Slider>
        <hr className="divider" />
        <AudioPlayer
          nextSlideBreakpoint={
            experience.nextPageBreakpoints[currentAutoplaySlide]
          }
          onNextSlide={handleNextSlide}
          audioFile={experience.audio}
        />
      </div>
    </div>
  );
}

export default MilestoneExperience
