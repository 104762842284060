import { useContext } from "react"
import { MenuContext } from "../MenuController"

import './index.scss'

function BackButton({ previewMode, vpsMode }) {
  const {
    setActiveMarker,
    setPageOpen,
    setOpen,
  } = useContext(MenuContext)

  const handleClick = () => {
    setActiveMarker(null)
    setPageOpen(false)

    if (!vpsMode) {
      setOpen(false);
    }
  }

  const handlePreviewClick = () => {
    const realClosePreviewButton = document.querySelector('#closePreviewButton')
    if (realClosePreviewButton) {
      realClosePreviewButton.click()
    }
  }

  return (
    <div className="back-button" onClick={previewMode ? handlePreviewClick : handleClick}>
      &#60; Back
    </div>
  )
}

export default BackButton
