function watchCurrentPosition(callback) {
  if ('geolocation' in navigator) {
    const watchId = navigator.geolocation.watchPosition(
      (success) => {
        callback(success)
      },
      (error) => {
        console.warn(error)
      },
      { enableHighAccuracy: true }
    )

    return () => {
      navigator.geolocation.clearWatch(watchId)
    }
  }
}

export default watchCurrentPosition
