import { gsap } from "gsap"
import { useContext, useEffect, useRef, useState } from 'react'
import { MenuContext } from '../MenuController'
import WorldMap from '../WorldMap'

import SITES from '../../data/sites'
import './index.scss'
import TextCarousel from "../TextCarousel"

const INITIAL_COPY = {
  name: 'Activation Sites',
  loc: '',
  copy: [
    `To further highlight Black stories, the team at ROSE has selected some special sties to further activate this experience. These sites have all played an important role in Black history. <span class="bold">Tap each location to learn more.</span>`,
  ],
}

function Sites() {
  const { activeMarker } = useContext(MenuContext)
  const [activeSite, setActiveSite] = useState(INITIAL_COPY)
  const ref = useRef()

  useEffect(() => {
    if (!(activeMarker === null && activeSite === INITIAL_COPY)) {
      const newSiteData = SITES.find((site) => site.name === activeMarker)

      const tl = gsap.timeline()

      tl.to(ref.current, {
        opacity: 0,
        duration: 0.1,
        onComplete: function() {
          if (activeMarker) {
            setActiveSite(newSiteData)
          } else {
            setActiveSite(INITIAL_COPY)
          }
        }
      })
      tl.to(ref.current, {
        opacity: 1,
        duration: 0.3,
      }, '+=0.1')
    }
  }, [activeMarker, activeSite])

  return (
    <div className="menu-page sites" ref={ref}>
      <div className="menu-content">
        <h1 className="menu-title">{activeSite.name}</h1>
        <h1 className="menu-title menu-location">{activeSite.loc}</h1>
        <TextCarousel items={activeSite.copy} />
      </div>
      <div className="menu-map">
        <WorldMap />
      </div>
    </div>
  );
}

export default Sites
