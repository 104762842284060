import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import asyncGetDistanceMatrix from "../../utils/asyncGetDistanceMatrix"
import { RouteContext } from "../RouteController"

import closeButton from './assets/close.png'
import './index.scss'

function InstructionDisplay() {
  const [stepIndex, setStepIndex] = useState(0)
  const { route, userCoords, showDisplay, setShowDisplay, arrived, setArrived } = useContext(RouteContext)

  const currentStep = useMemo(() => (route?.routes?.[0]?.legs?.[0]?.steps?.[stepIndex]), [stepIndex, route])
  const instructions = useMemo(() => (currentStep?.instructions), [currentStep])

  const completeStep = useCallback(() => {
    if (stepIndex < (route?.routes?.[0]?.legs?.[0]?.steps?.length - 1)) {
      setStepIndex((state) => state + 1)
    } else {
      setArrived(true)
    }
  }, [stepIndex, route?.routes, setArrived])

  const closeDisplay = useCallback(() => {
    setShowDisplay(false)
  }, [setShowDisplay])

  useEffect(() => {
    if (userCoords && currentStep && !arrived) {
      const calcDistance = async () => {
        const service = new window.google.maps.DistanceMatrixService()

        const destinationObj = currentStep['end_location']
        const request = {
          origins: [userCoords],
          destinations: [destinationObj],
          travelMode: window.google.maps.TravelMode.WALKING,
          unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          avoidHighways: true,
          avoidTolls: true,
          avoidFerries: true,
        }

        const response = await asyncGetDistanceMatrix(service, request)
        const { rows } = response;

        if (rows?.[0]?.elements?.[0]?.distance?.value <= 30) {
          completeStep()
        }
      }

      calcDistance()
    }
  }, [arrived, completeStep, currentStep, userCoords])

  useEffect(() => {
    setStepIndex(0)
  }, [route])

  return (
    <div className={`instruction-display ${showDisplay ? 'visible' : ''}`}>
      <div className="text-contents">
        {arrived ? (
          <>
            <div className="vps-instructions">Point the camera to the area shown.</div>
            <div className="vps-instructions">Try to match the image closely.</div>
          </>
        ) : (
          <>
            <div className="route-instructions" dangerouslySetInnerHTML={{ __html: instructions }}></div>
            {currentStep && (
              <div className="distance">{Math.round(currentStep.distance.value * 3.28084)} feet</div>
            )}
          </>
        )}
      </div>
      <button className="close-button" onClick={closeDisplay}>
        <img src={closeButton} alt="close instruction display" />
      </button>
    </div>
  )
}

export default InstructionDisplay
