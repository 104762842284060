import { useEffect } from 'react';

import mannahattaParkVO from "../assets/audio/BLM_VO_MP-NY.mp3";
import centralParkVO from "../assets/audio/BLM_VO_CP-NY.mp3";
import expositionParkVO from "../assets/audio/BLM_VO_LA.mp3";
import nationalMallVO from "../assets/audio/BLM_VO_DC.mp3";
import audio1 from "../assets/audio/1_We Shall Overcome.mp3";
import audio2 from "../assets/audio/2_Boycotts for Equality.mp3";
import audio3 from "../assets/audio/3_Southern Sit-Ins.mp3";
import audio4 from "../assets/audio/4_Black Power.mp3";
import audio5 from "../assets/audio/5_Resistance through Art.mp3";
import audio6 from "../assets/audio/6_Black Lives Matter.mp3";
import audio7 from "../assets/audio/7_The Fight Continues.mp3";


const sounds = [
  mannahattaParkVO,
  centralParkVO,
  expositionParkVO,
  nationalMallVO,
  audio1,
  audio2,
  audio3,
  audio4,
  audio5,
  audio6,
  audio7,
];

const assets = [];

function usePreloadAssets() {
  useEffect(() => {
    sounds.forEach((sound) => {
      const asset = new Audio(sound);

      assets.push(asset);
    });
  }, []);
}

export default usePreloadAssets;
