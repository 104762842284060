import { Children, cloneElement, isValidElement, useContext, useEffect, useMemo, useRef, useState } from "react"
import SITES from "../../data/sites"
import asyncGetDistanceMatrix from "../../utils/asyncGetDistanceMatrix"
import asyncCalculateRoute from '../../utils/asyncCalculateRoute'
import watchCurrentPosition from "../../utils/watchCurrentPosition"
import { RouteContext } from '../RouteController'
import classNames from "classnames"

import locationMarker from './assets/location-marker.png'
import positionMarker from './assets/position-marker.png'
import './index.scss'

const mapOptions = {
  zoom: 16,
  disableDefaultUI: true,
  tilt: 45,
}

const REROUTE_TIMEOUT = 15000;
const ROUTE_UPDATE_TIMEOUT = 20000;

function SiteFinder() {
  const distanceMatrixService = useMemo(() => new window.google.maps.DistanceMatrixService(), []);

  const {
    nearbySite,
    setNearbySite,
    setUserCoords,
    setUserHeading,
    userCoords,
  } = useContext(RouteContext)

  useEffect(() => {
    const handleCurrentPosition = ({ coords }) => {
      const originObj = { lat: coords.latitude, lng: coords.longitude }
      setUserCoords(originObj)

      const { heading } = coords
      setUserHeading(heading)
    }

    return watchCurrentPosition(handleCurrentPosition)
  }, [setUserCoords, setUserHeading])

  useEffect(() => {
    if (nearbySite === null && userCoords) {
      const findNearbySites = async () => {
        try {
          const nearbySites = []

          for (const site of SITES) {
            const destinationObj = { lat: site.lat, lng: site.lng }

            const request = {
              origins: [userCoords],
              destinations: [destinationObj],
              travelMode: window.google.maps.TravelMode.WALKING,
              unitSystem: window.google.maps.UnitSystem.IMPERIAL,
              avoidHighways: true,
              avoidTolls: true,
              avoidFerries: true,
            }

            const { rows } = await asyncGetDistanceMatrix(distanceMatrixService, request)
            const duration = rows?.[0]?.elements?.[0]?.duration?.value;

            if (rows?.[0]?.elements?.[0]?.duration?.value <= 1200) {
              nearbySites.push({
                duration,
                site
              })
            }
          }

          if (nearbySites.length) {
            const nearbySitesSorted = nearbySites.sort((first, second) => first.duration - second.duration);
            setNearbySite(nearbySitesSorted[0].site)
          } else {
            setNearbySite(false)
          }
        } catch {
            setNearbySite(false);
        }
      }

      findNearbySites()
    }
  }, [nearbySite, setNearbySite, userCoords]);

  return null
}

function RouteMap({ children, options }) {
  const [locked, setLocked] = useState(false)
  const [map, setMap] = useState(null)
  const [timestamp, setTimestamp] = useState(null)
  const ref = useRef()
  const { nearbySite, route, userCoords, userHeading, setRoute } = useContext(RouteContext)

  const directionsService = useMemo(() => new window.google.maps.DirectionsService(), []);
  const directionsRenderer = useMemo(() => new window.google.maps.DirectionsRenderer({
    suppressMarkers: true,
    polylineOptions: {
      icons: [
        {
          repeat: "18px",
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: "#5b42e1",
            fillOpacity: 1,
            strokeColor: "#20417a",
            strokeOpacity: 1,
            strokeWeight: 2,
            scale: 5,
          },
        },
      ],
      strokeOpacity: 0,
    },
    preserveViewport: true,
  }), []);

  useEffect(() => {
    if (ref.current && !map && userCoords) {
      const newMap = new window.google.maps.Map(ref.current, {
        mapId: "edc76cc2f1d16861",
        center: userCoords,
      })

      newMap.addListener('mousedown', () => {
        setLocked(false)
      })

      setMap(newMap)
    }
  }, [ref, map, userCoords])

  useEffect(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  useEffect(() => {
    if (map && userHeading) {
      map.setHeading(userHeading)
    }
  }, [map, userHeading])

  useEffect(() => {
    if (map && userCoords && nearbySite) {
      const now = Date.now();

      if (timestamp !== null && now - timestamp < REROUTE_TIMEOUT) {
        return;
      }

      const findRoute = async () => {
        const request = {
          origin: { lat: userCoords.lat, lng: userCoords.lng },
          destination: { lat: nearbySite.lat, lng: nearbySite.lng },
          travelMode: window.google.maps.TravelMode.WALKING,
          unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          avoidHighways: true,
          avoidTolls: true,
          avoidFerries: true,
        };

        const route = await asyncCalculateRoute(directionsService, request);
        directionsRenderer.setMap(map);
        directionsRenderer.setDirections(route);

        setTimestamp(now);
        setRoute(route);
      };

      if (route) {
        const routePolylineEncoded = route?.routes?.[0]?.["overview_polyline"];
        const routePolylineDecoded =
          window.google.maps.geometry.encoding.decodePath(routePolylineEncoded);
        const routePolyline = new window.google.maps.Polyline({
          path: routePolylineDecoded,
        });

        const isOnRoute = window.google.maps.geometry.poly.isLocationOnEdge(
          userCoords,
          routePolyline,
          /*
            10e-5 is about 11m https://stackoverflow.com/posts/28787439/revisions
            https://calculator.name/scientific-notation-to-decimal/10e-1
          */
          3 * 10e-5
        );

        if (!isOnRoute || now - timestamp >= ROUTE_UPDATE_TIMEOUT) {
          findRoute();
        }
      } else {
        findRoute()
      }
    }
  }, [map, nearbySite, userCoords, route, setRoute, timestamp]);

  useEffect(() => {
    if (route) {
      setLocked(true)
    }
  }, [route])

  useEffect(() => {
    if (userCoords && locked) {
      map.setCenter(userCoords)
    }
  }, [locked, map, userCoords])

  useEffect(() => {
    if (userHeading && locked) {
      map.setHeading(userHeading)
    }
  }, [locked, map, userHeading])

  return (
    <>
      <div className={`map map${nearbySite ? '--visible' : '--hidden'}`} id="route-map" ref={ref}></div>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map })
        }
      })}
    </>
  )
}

function CurrentLocationMarker({ map }) {
  const [marker, setMarker] = useState()
  const { userCoords } = useContext(RouteContext)

  useEffect(() => {
    if (!marker && map) {
      setMarker(new window.google.maps.Marker({
        clickable: false,
        map,
        icon: positionMarker,
        zIndex: 2,
      }))
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [map, marker])

  useEffect(() => {
    if (marker && userCoords) {
      marker.setOptions({
        position: userCoords,
      })
    }
  }, [marker, userCoords])

  return null
}

function SiteMarker({ map }) {
  const [marker, setMarker] = useState()
  const { nearbySite } = useContext(RouteContext)

  useEffect(() => {
    if (!marker && map) {
      setMarker(new window.google.maps.Marker({
        clickable: false,
        map,
        zIndex: 2,
        icon: locationMarker,
      }))
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [map, marker])

  useEffect(() => {
    if (marker && nearbySite) {
      marker.setOptions({
        position: {
          lat: nearbySite.lat,
          lng: nearbySite.lng,
        },
      })
    }
  }, [marker, nearbySite])

  return null
}

function RouteMapController() {
  const { nearbySite } = useContext(RouteContext)

  return (
    <div
      className={classNames(
        "route-map",
        !nearbySite && "route-map--hidden"
      )}>
      <SiteFinder />
      <RouteMap options={mapOptions}>
        {nearbySite && (
          <CurrentLocationMarker />
        )}
        {nearbySite && (
          <SiteMarker />
        )}
      </RouteMap>
    </div>
  );
}

export default RouteMapController
