function MenuSvg() {
  return (
    <svg width="100%" viewBox="0 0 375 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bd_27_768)">
        <path d="M0 16H104.902C121.016 16 135.556 25.6682 141.789 40.5271L150.614 61.5659C164.332 94.2688 210.668 94.2688 224.386 61.566L233.211 40.5272C239.444 25.6683 253.984 16 270.098 16H375V109H0V16Z" fill="#161616" fillOpacity="0.65" shapeRendering="crispEdges"/>
      </g>
      <defs>
        <filter id="filter0_bd_27_768" x="-12" y="0" width="399" height="121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_27_768"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="-4"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="effect1_backgroundBlur_27_768" result="effect2_dropShadow_27_768"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_27_768" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
}

export default MenuSvg
