import { createContext, useEffect, useState } from "react"

export const MenuContext = createContext({
  activeMarker: null,
  activePage: null,
  open: false,
  pageOpen: false,
  setActiveMarker: () => {},
  setActivePage: () => {},
  setOpen: () => {},
  setPageOpen: () => {},
})

function MenuController({ children }) {
  const [activeMarker, setActiveMarker] = useState(null)
  const [activePage, setActivePage] = useState(null)
  const [open, setOpen] = useState(false)
  const [pageOpen, setPageOpen] = useState(false)

  useEffect(() => {
    const handleSnapshotPreviewOpened = () => {
      if (open) {
        setOpen(false)

        const handleSnapshotPreviewClosed = () => {
          setOpen(true)
          window.removeEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)
        }

        window.addEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)
      }
    }
    window.addEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)

    return () => {
      window.removeEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)
    }
  }, [open])

  return (
    <MenuContext.Provider value={{
      activeMarker,
      activePage,
      open,
      pageOpen,
      setActiveMarker,
      setActivePage,
      setOpen,
      setPageOpen,
    }}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuController
