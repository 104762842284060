import { gsap } from 'gsap'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import BackButton from '../BackButton'
import MenuButton from '../MenuButton'
import { MenuContext } from '../MenuController'
import SoundButton from '../SoundButton'

import './index.scss'

function Toolbar({ vpsMode }) {
  const { pageOpen } = useContext(MenuContext)
  const [open, setOpen] = useState(true)
  const [previewMode, setPreviewMode] = useState(false)
  const menuRef = useRef()

  useEffect(() => {
    const handleSnapshotPreviewOpened = () => {
      setPreviewMode(true)
    }

    const handleSnapshotPreviewClosed = () => {
      setPreviewMode(false)
    }

    window.addEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)
    window.addEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)

    return () => {
      window.removeEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)
      window.removeEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)
    }
  }, [])

  useEffect(() => {
    const handleSnapshotPreviewOpened = () => {
      if (open) {
        setOpen(false)

        const handleSnapshotPreviewClosed = () => {
          setOpen(true)
          window.removeEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)
        }

        window.addEventListener('mediarecorder-previewclosed', handleSnapshotPreviewClosed)
      }
    }
    window.addEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)

    return () => {
      window.removeEventListener('mediarecorder-previewopened', handleSnapshotPreviewOpened)
    }
  }, [open])

  useLayoutEffect(() => {
    if (previewMode) {
      gsap.to(menuRef.current, {
        duration: 0.7,
        ease: 'expo.out',
        opacity: 0,
      })
    } else {
      gsap.to(menuRef.current, {
        duration: 0.7,
        ease: 'expo.out',
        opacity: 1,
      })
    }
  }, [previewMode])

  return (
    <nav className="toolbar">
      {(pageOpen || previewMode) ? (
        <BackButton previewMode={previewMode} vpsMode={vpsMode} />
      ) : (
        <MenuButton />
      )}
      <div ref={menuRef}>
        {!previewMode && <SoundButton />}
      </div>
    </nav>
  )
}

export default Toolbar
