import classNames from "classnames";

import 'rc-slider/assets/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.scss'

function RepositionOverlay({ experience, onClose }) {
  return (
    <div 
      className={
        classNames(
          "reposition-overlay", 
        )
      }
    >
      Drag to reposition the experience. Tap the checkmark when complete.
    </div>
  )
}

export default RepositionOverlay
