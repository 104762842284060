import showStatues from '../../commonMethods/showStatues';
import showTexturedText from '../../commonMethods/showTexturedText';
import showToolbar from '../../commonMethods/showToolbar';

const THREE = window.THREE

const EXPERIENCE_WRAPPER_INITIAL_SCALE = 0.35;

const vpsController = {
  schema: {
    wayspotName: { type: 'string' },
    txtImage: { type: 'string' },
  },
  init() {
    this.showStatues = showStatues.bind(this);
    this.startExperience = this.startExperience.bind(this);
    this.showToolbar = showToolbar.bind(this);
    this.showTexturedText = showTexturedText.bind(this);
    this.setVpsModels = this.setVpsModels.bind(this);

    this.scene = document.getElementById("a-frame-scene");
    this.camera = document.getElementById("camera");
    this.experienceWrapper = document.getElementById("experience-wrapper");
    this.textBasic = document.getElementById("text-basic");
    // used in showTexturedText
    this.textTextured = document.getElementById("text-textured");
    this.textTexturedForLocation = document.getElementById("text-textured-for-location")
    this.experienceCenter = document.getElementById(
      "experience-center-pointer"
    );

    this.textTexturedForLocation.setAttribute('src', this.data.txtImage);


    this.activeStatues = [];

    this.experienceWrapper.setAttribute("visible", true);

    this.vps = document.getElementById("vps");
    this.vpsHider = document.getElementById("vps-hider");
    this.vpsPosition = document.getElementById("vps-pointer-mesh");

    this.setVpsModels();

    this.pointerPosition = new THREE.Vector3();
    this.pointerQuaternion = new THREE.Quaternion();

    const initialWayspotFoundHandler = () => {
      this.startExperience();

      this.camera.removeEventListener(
        "wayspotfound",
        initialWayspotFoundHandler
      );
    };

    const wayspotPositionUpdateHandler = () => {
      const vpsPositionMesh =
        this.vpsPosition.object3D.getObjectByName("position-pointer");

      vpsPositionMesh.getWorldPosition(this.pointerPosition);
      vpsPositionMesh.getWorldQuaternion(this.pointerQuaternion);

      this.experienceWrapper.object3D.scale
        .copy(vpsPositionMesh.scale)
        .multiplyScalar(EXPERIENCE_WRAPPER_INITIAL_SCALE);

      try {
        this.experienceWrapper.object3D.position.set(
          this.pointerPosition.x,
          this.pointerPosition.y,
          this.pointerPosition.z
        );
        this.experienceWrapper.object3D.rotation.setFromQuaternion(
          this.pointerQuaternion
        );
      } catch (e) {
        console.error(e);
      }
    };

    this.camera.addEventListener("wayspotfound", initialWayspotFoundHandler);
    this.camera.addEventListener("wayspotfound", wayspotPositionUpdateHandler);
    this.camera.addEventListener("wayspotupdate", wayspotPositionUpdateHandler);
  },
  startExperience() {
    try {
      this.textBasic.setAttribute("visible", "true");
      this.textBasic.setAttribute("opacity", "1");

      this.showStatues();
      this.showToolbar();

      this.camera.emit("experience-placed");
    } catch (e) {
      console.log(e);
    }
  },
  setVpsModels() {
    if (this.data.wayspotName) {
      this.vps.setAttribute("named-wayspot", `name: ${this.data.wayspotName}`);
      this.vpsHider.setAttribute(
        "gltf-model",
        `#vps-mesh-${this.data.wayspotName}`
      );
      this.vpsPosition.setAttribute(
        "gltf-model",
        `#vps-pointer-${this.data.wayspotName}`
      );
    }
  }
};

export default vpsController
