const THREE = window.THREE


const reposition = {
  init() {
    this.changeOpacity = this.changeOpacity.bind(this);


    this.textBasic = document.getElementById('text-basic');
    this.textTextured = document.getElementById('text-textured');
    this.statueModels = document.querySelectorAll('.statue-model');
    this.modelTouchboxes = document.querySelectorAll('.model-touch-box');
    this.recorder = document.getElementById('recorder');

    this.statueModels.forEach((el) => {
      el.removeAttribute('proximity-trigger')
      
      el.object3D.traverse((child) => {
        if (child.type === 'Mesh') {
          child.material.opacity = 0.3;
          child.material.transparent = true;
        }
      })
    });
    this.modelTouchboxes.forEach((el) => {
      el.classList.remove('cantap');
    });

    setTimeout(() => {
      this.changeOpacity('0.5');
      this.textTextured.setAttribute('visible', 'false')
    }, 200)
  },
  remove() {
    this.changeOpacity('1');
    this.textTextured.setAttribute('visible', 'true')
    this.statueModels.forEach((el) => {
      el.setAttribute('proximity-trigger', '');

      el.object3D.traverse((child) => {
        if (child.type === 'Mesh') {
          child.material.opacity = 1;
          child.material.transparent = false;
        }
      })
    })
    this.modelTouchboxes.forEach((el) => {
      el.classList.add('cantap');
    });
  },
  changeOpacity(value) {
    this.textBasic.setAttribute('opacity', value);
  }
}

export default reposition
