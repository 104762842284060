import { gsap } from "gsap"
import classNames from "classnames"
import { useRef } from "react"

import './index.scss'

function IconButton({ children, className, dark, light = true, onClick }) {
  const ref = useRef()

  const handleMouseDown = () => {
    gsap.to(ref.current, { scale: 0.8, duration: 0.1, })
  }
  
  const handleMouseUp = () => {
    gsap.to(ref.current, { scale: 1, duration: 0.1, })
  }

  return (
    <button
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onClick={onClick}
      className={classNames('icon-button', className, { light, dark })}
      ref={ref}
    >
      {children}
    </button>
  )
}

export default IconButton
