import mannahattaParkVO from "../assets/audio/BLM_VO_MP-NY.mp3";
import centralParkVO from "../assets/audio/BLM_VO_CP-NY.mp3";
import expositionParkVO from "../assets/audio/BLM_VO_LA.mp3";
import nationalMallVO from "../assets/audio/BLM_VO_DC.mp3";

import mannahattaParkImage from "../assets/wayspotImages/mannahatta-p.jpg";
import centralParkImage from "../assets/wayspotImages/dove-carving.jpg";
import expositionParkImage from "../assets/wayspotImages/wishing-on-a.jpg";
import nationalMallImage from "../assets/wayspotImages/lincoln-nati.jpg";

import mannahattaParkTxtImage from "../assets/wayspotTxtImages/BLM_Text-Design_MP-NY.png";
import centralParkTxtImage from "../assets/wayspotTxtImages/BLM_Text-Design_CP-NY.png";
import expositionParkTxtImage from "../assets/wayspotTxtImages/BLM_Text-Design_LA.png";
import nationalMallTxtImage from "../assets/wayspotTxtImages/BLM_Text-Design_DC.png";

const id = {
  MANNAHATTA_PARK: "mannahatta-p",
  CENTRAL_PARK: "dove-carving",
  EXPOSITION_PARK: "wishing-on-a",
  NATIONAL_MALL_1: "lincoln-nati",
  HOME_WINDOW: "17459e78805a",
  SZACH_MAT: "szach-i-mat",
  TENIS_GRANITOWY: "tenis-granit",
};

const SITES = [
  {
    id: id.CENTRAL_PARK,
    name: "Central Park",
    loc: "New York, New York",
    title: "Central Park, New York",
    image: centralParkImage,
    txtImage: centralParkTxtImage,
    wayspotName: "Dove Carving",
    lat: 40.773942,
    lng: -73.970827,
    copy: [
      `Seneca Village was a small African American community that existed in the mid-1800s in what is now Central Park. The village was established in 1825 by Black property owners, including Andrew Williams and Epiphany Davis, on land they purchased that, at that point, was New York City’s boundaries. At its height, Seneca Village had around 250 residents, several churches, a school, and a cemetery.`,
      `In the 1850s, the city of New York began plans to create a large public park in the area, and the residents of Seneca Village were forcibly evicted from their homes and businesses to make way for the park, a clear example of racial discrimination and the erasure of Black history in the city.`,
      `Today, there is little visible evidence of Seneca Village in Central Park, but some archeological excavations have been done to uncover artifacts and learn more about the community. Additional efforts to acknowledge and commemorate the history of Seneca Village have also taken hold in recent years.<span class="superscript">1,2</span>`,
    ],
    slides: [
      <>
        Seneca Village was a small African American community that existed in
        the mid-1800s in what is now Central Park. The village was established
        in 1825 by Black property owners, including Andrew Williams and Epiphany
        Davis, on land they purchased that had not yet been absorbed by New York
        City’s boundaries. At its height, Seneca Village had around 250
        residents, several churches, a school, and a cemetery.
      </>,
      <>
        In the 1850s, the city of New York began plans to create a large public
        park in the area, and the residents of Seneca Village were forcibly
        evicted from their homes and businesses to make way for the park, a
        clear example of racial discrimination and the erasure of Black history
        in the city.
      </>,
      <>
        Today, there is little visible evidence of Seneca Village in Central
        Park, but some archeological excavations have been done to uncover
        artifacts and learn more about the community. Additional efforts to
        acknowledge and commemorate the history of Seneca Village have also
        taken hold in recent years.<sup>1,2</sup>
      </>,
    ],
    nextPageBreakpoints: [26, 44],
    audio: centralParkVO,
  },
  {
    id: id.EXPOSITION_PARK,
    name: "Exposition Park",
    loc: "Los Angeles, California",
    title: "Exposition Park, Los Angeles",
    image: expositionParkImage,
    txtImage: expositionParkTxtImage,
    wayspotName: "Wishing on a Star",
    lat: 34.016293,
    lng: -118.28348,
    copy: [
      `The National Mall has played an important role in the history of the United States, including in the Civil Rights Movement and other instances of Black resistance. One of these, the March on Washington for Jobs and Freedom in 1963, was one of the largest civil rights demonstrations in U.S. history.<span class="superscript">1</span>`,
      `The National Mall also features several monuments and memorials related to African American history and the Civil Rights Movement. The Smithsonian National Museum of African American History and Culture, which opened in 2016, is the only national museum dedicated exclusively to the documentation of African American life, history, and culture.<span class="superscript">2</span>`,
      `The National Mall continues to be a site of protest and activism, including the ongoing movement to recognize the rights of Black Lives Matter and call for an end to police brutality and racial injustice.`,
    ],
    slides: [
      <>
        Exposition Park is a large public park and cultural complex located in
        Los Angeles, CA. The park includes several museums, sports facilities,
        and other attractions, including the California African American Museum
        (CAAM). The CAAM was founded in 1984 with a mission to collect,
        preserve, and interpret the history, art, and culture of African
        Americans in California and the western United States.
      </>,
      <>
        The museum's permanent collection includes art, artifacts, and
        photographs that tell the story of the African American experience in
        the region, as well as special exhibitions on a variety of subjects. It
        is an important institution that provides a space for African American
        culture and history to be celebrated and shared with the community in
        Los Angeles and beyond.<sup>1,2</sup>
      </>,
    ],
    nextPageBreakpoints: [26],
    audio: expositionParkVO,
  },
  {
    id: id.NATIONAL_MALL_1,
    name: "National Mall",
    loc: "Washington D.C.",
    title: "National Mall, Washington D.C.",
    image: nationalMallImage,
    txtImage: nationalMallTxtImage,
    wayspotName: "Lincoln National Memorial",
    lat: 38.88925,
    lng: -77.049601,
    copy: [
      `The National Mall has played an important role in the history of the United States, including in the Civil Rights Movement and other instances of Black resistance. One of these, the March on Washington for Jobs and Freedom in 1963, was one of the largest civil rights demonstrations in U.S. history.<span class="superscript">1</span>`,
      `The National Mall also features several monuments and memorials related to African American history and the Civil Rights Movement. The Smithsonian National Museum of African American History and Culture, which opened in 2016, is the only national museum dedicated exclusively to the documentation of African American life, history, and culture.<span class="superscript">2</span>`,
      `The National Mall continues to be a site of protest and activism, including the ongoing movement to recognize the rights of Black lives and call for an end to police brutality and racial injustice.`,
    ],
    slides: [
      <>
        The National Mall has played an important role in the history of the
        United States, including in the Civil Rights Movement and other
        instances of Black resistance. One of these, the March on Washington for
        Jobs and Freedom in 1963, was one of the largest civil rights
        demonstrations in U.S. history.<sup>1</sup> The National Mall also
        features several monuments and memorials related to African American
        history and the Civil Rights Movement.
      </>,
      <>
        The Smithsonian National Museum of African American History and Culture,
        which opened in 2016, is the only national museum dedicated exclusively
        to the documentation of African American life, history, and culture.
        <sup>2</sup>
        <br />
        <br />
        The National Mall continues to be a site of protest and activism,
        including the ongoing movement to recognize the rights of Black lives
        and call for an end to police brutality and racial injustice.
      </>,
    ],
    nextPageBreakpoints: [25],
    audio: nationalMallVO,
  },
];

export default SITES;
