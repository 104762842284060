import showStatues from '../../commonMethods/showStatues';
import showTexturedText from '../../commonMethods/showTexturedText';
import showToolbar from "../../commonMethods/showToolbar";

const THREE = window.THREE


const tapPlacer = {
  init() {
    this.updateExperiencePosition = this.updateExperiencePosition.bind(this);
    this.showStatues = showStatues.bind(this);
    this.onInitialTap = this.onInitialTap.bind(this);
    this.showToolbar = showToolbar.bind(this);
    this.remove = this.remove.bind(this);
    this.showTexturedText = showTexturedText.bind(this);

    this.scene = document.getElementById('a-frame-scene');
    this.camera = document.getElementById('camera');
    this.model = document.getElementById('model');
    this.experienceWrapper = document.getElementById('experience-wrapper');
    this.experienceDrag = document.getElementById('experience-drag');
    this.experience = document.getElementById('experience');
    this.textBasic = document.getElementById('text-basic');
    this.textTextured = document.getElementById('text-textured');
    this.experienceCenter = document.getElementById('experience-center-pointer');
    this.cameraPosition = new THREE.Vector3();
    this.cameraQuat = new THREE.Quaternion()

    this.activeStatues = [];

    this.textBasic.setAttribute('visible', 'true');
    this.camera.addEventListener('click', this.onInitialTap);
    this.experienceWrapper.setAttribute('visible', true);
  },
  remove() {
    this.camera.removeEventListener('click', this.onInitialTap)
  },
  tick() {
    this.updateExperiencePosition();
  },
  updateExperiencePosition() {
    this.camera.object3D.getWorldPosition(this.cameraPosition);

    try {
      this.experience.object3D.position.set(this.cameraPosition.x, 0, this.cameraPosition.z)
      this.experienceWrapper.object3D.rotation.y = this.camera.object3D.rotation.y
    } catch (e) {
      console.log(e)
    }
  },
  onInitialTap() {
    try {
      this.textBasic.setAttribute("visible", "true");
      this.textBasic.setAttribute("opacity", "1");

      this.showStatues();
      this.showToolbar();

      this.scene.removeAttribute('coaching-overlay');
      this.camera.removeEventListener('click', this.onInitialTap);
      this.camera.removeAttribute('tap-placer');

      this.experienceCenter.setAttribute('drag-control', 'enabled: false');

      this.camera.emit('experience-placed');
    } catch (e) {
      console.log(e)
    }
  },
}

export default tapPlacer
