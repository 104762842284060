// import audio1 from '../assets/audio/audiomass-output_256_Stereo.mp3';
// import audio2 from '../assets/audio/audiomass-output_mono_256kbps.mp3';
// import audio3 from '../assets/audio/BLM_VO_MN.wav';
import audio1 from '../assets/audio/1_We Shall Overcome.mp3';
import audio2 from '../assets/audio/2_Boycotts for Equality.mp3';
import audio3 from '../assets/audio/3_Southern Sit-Ins.mp3';
import audio4 from '../assets/audio/4_Black Power.mp3';
import audio5 from '../assets/audio/5_Resistance through Art.mp3';
import audio6 from '../assets/audio/6_Black Lives Matter.mp3';
import audio7 from '../assets/audio/7_The Fight Continues.mp3';


const STATUES = [
  {
    id: 'milestone-1',
    modelSrc: "#statue-model-1",
    textSrc: "#texture1",
    title: '“We shall overcome…”',
    slides: [
      <>
        Martin Luther King Jr., a minister and activist, was the most visible spokesperson and leader in the Civil Rights Movement. Best known for his work as a nonviolent protestor, organizer, and orator, Dr. King was already an executive member of the NAACP by the early 1950s, and his continued efforts led directly to the Civil Rights Act of 1964 and the Voting Rights Act of 1965.
      </>,
      <>
        Dr. King was awarded the Nobel Peace Prize in 1964 for his work to end racial segregation and discrimination. King was assassinated in 1968, but his legacy continues to be a global inspiration for civil rights and social justice and has become a pillar of Black resistance.<sup>1,2</sup>
      </>,
    ],
    nextPageBreakpoints: [24],
    audio: audio1,
  },
  {
    id: 'milestone-2',
    modelSrc: "#statue-model-2",
    textSrc: "#texture2",
    title: 'Boycotts for Equality',
    slides: [
      <>
        Rosa Parks was a civil rights activist who became an icon of Black resistance when she refused to give up her seat on a bus to a white passenger in 1955. Her arrest for this act of civil disobedience led to a year-long bus boycott in Montgomery, AL, and eventually to a Supreme Court decision that declared the city’s bus segregation laws unconstitutional.<sup>1</sup> 
      </>,
      <>
        Parks’ act of defiance was a vital spark in igniting the Civil Rights Movement and the Montgomery Bus Boycott, which was a catalyst for the Freedom Ride anti-segregation movement of the 1960s.<sup>2</sup>
      </>,
    ],
    nextPageBreakpoints: [21],
    audio: audio2,
  },  
  {
    id: 'milestone-3',
    modelSrc: "#statue-model-3",
    textSrc: "#texture3",
    title: 'Southern Sit-Ins',
    slides: [
      <>
        In 1960, a group of African American college students protested racial segregation at department store lunch counters by refusing to leave after being denied service. Known as the Greensboro Sit-ins, these acts of resistance inspired protests throughout the southern U.S. and were instrumental in the desegregation of Woolworth's and other department stores, a symbolic victory for the Civil Rights Movement.
      </>,
      <>
        The protests also led to the formation of the Student Nonviolent Coordinating Committee, which played a key role in the Civil Rights Movement and the fight for desegregation and voting rights.<sup>1</sup>
      </>,
    ],
    nextPageBreakpoints: [24],
    audio: audio3,
  },  
  {
    id: 'milestone-4',
    modelSrc: "#statue-model-4",
    textSrc: "#texture4",
    title: 'Black Power',
    slides: [
      <>
        The Black Panther Party, founded in 1966 by Huey P. Newton and Bobby Seale in Oakland, California, promoted self-defense and political empowerment for the African American community through programs like community health clinics and free breakfast programs for children.<sup>1</sup> The Party's militant approach and rhetoric led to conflicts with law enforcement and the FBI, which targeted the group with surveillance and repression.<sup>2</sup>
      </>,
      <>
        At the 1968 Summer Olympics, runners Tommie Smith and John Carlos raised their fists in a Black Power salute during their medal ceremony to show solidarity with oppressed Black people and express their concerns about human rights worldwide. The gesture became an iconic moment in the Civil Rights Movement, bringing the Black Panthers and the Black Power Movement to the center of the political stage.<sup>3</sup>
      </>,
    ],
    nextPageBreakpoints: [24],
    audio: audio4,
  },
  {
    id: 'milestone-5',
    modelSrc: "#statue-model-5",
    textSrc: "#texture5",
    title: 'Resistance through Art',
    slides: [
      <>
        Art has been a channel to showcase Black resistance throughout history. The Black Arts Movement (BAM) emerged in the 1960s as the cultural counterpart of the Civil Rights Movement, seeking to create a distinct African American literary and artistic identity.
      </>,
      <>
        Prominent figures of the BAM included, among many others, Maya Angelou, a poet and author best known for her memoir I Know Why the Caged Bird Sings; James Baldwin, a novelist and playwright; Sonia Sanchez, a poet and feminist; and Larry Neal, a poet and essayist.<sup>1</sup> These artists of the Civil Rights Movement era used their mediums as a platform to expose the realities of Black life and paved the way for Black creators for decades to come.
      </>,
      <>
        Photographer Gordon Parks, known for his work highlighting poverty and racial discrimination from the 1940s - 1970s, produced images that were a powerful tool in raising awareness about these issues.
      </>,
      <>
        Jean-Michel Basquiat rose to fame in the 80s for his raw and powerful style that often resisted dominant narratives. Focusing instead on the oppression of his peers within race and class struggles, his work manifested themes and influences that can be found in art, music, and expression today.
      </>,
      <>
        The Black Arts Movement and prominent Black creatives such as these helped lay the foundation for modern-day spoken word, hip-hop, and more.<sup>2</sup>
      </>,
    ],
    nextPageBreakpoints: [15, 44, 55, 74],
    audio: audio5,
  },  
  {
    id: 'milestone-6',
    modelSrc: "#statue-model-6",
    textSrc: "#texture6",
    title: 'Black Lives Matter',
    slides: [
      <>
        Black Lives Matter (BLM) is a decentralized movement and global organization founded in 2013 in response to the acquittal of the murderer of Trayvon Martin. The organization's mission, to combat and eradicate systemic racism and violence against Black people, focuses on both immediate issues like police brutality and criminal justice system reform as well as broader racial inequalities in housing, education, and healthcare.
      </>,
      <>
        BLM began as a trending hashtag, but evolved into a global movement to create a world where Black lives are valued.<sup>1</sup> 2016 saw BLM grow in visibility when 49ers quarterback Colin Kaepernick took a knee during the national anthem to protest the systematically brutal treatment of Black Americans. This sparked a movement among athletes to publicly speak out against racial injustice.<sup>2</sup>
      </>,
    ],
    nextPageBreakpoints: [25],
    audio: audio6,
  },
  {
    id: 'milestone-7',
    modelSrc: "#statue-model-7",
    textSrc: "#texture7",
    title: 'The Fight Continues',
    slides: [
      <>
        The Black Lives Matter movement gained renewed attention in recent years after George Floyd, an unarmed Black man, was murdered by a Minneapolis police officer in May 2020.
      </>,
      <>
        His murder, along with the deaths of many other Black Americans including Breonna Taylor, an unarmed Black woman who was murdered by Louisville Police in her apartment in March 2020, and Ahmaud Arbery, an unarmed Black man who was shot and killed while jogging in February 2020, sparked widespread protests and civil unrest across the United States and around the world.
      </>,
      <>
        These demonstrations highlighted the importance of intersectionality and international mobilization against oppression. Ten years after the founding of BLM, we are still resisting systemic oppression. It is crucial to continue to raise awareness and take action against racial injustices. We can all do our part by supporting the BLM movement and the fight for racial justice through education and speaking up against racism.<sup>1,2</sup>
      </>,
    ],
    nextPageBreakpoints: [11, 34],
    audio: audio7,
  },
];

export default STATUES;
